.pricing-container {
  padding-top: 100px;

  .row{
    margin: auto 0;
  }
  .card-container {
    
  display: flex;
  justify-content: center;
  }

  .card {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 350px;
    height: 550px;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }

    .card-popular {
      background-color: #f4a261;
      color: white;
      font-weight: bold;
      padding: 5px;
      text-align: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .card-header {
      background-color: #ffb703;
      color: white;
      padding: 15px;
      border-bottom: 1px solid #ddd;
    }

    .card-title {
      background-color: #000000;
      color: white;
      margin: 0;
      font-size: 2rem;
      font-weight: 800;
    }

    .card-body {
      padding: 0;
      background-image: url('../../../assets/frontpage/pricing/pricing-bg.png');
      background-size: cover;

      .image-container {
        display: flex;
        justify-content: center;
        position: relative;
      }

      .pricing-image {
        max-width: 100%;
        padding: 20px;
      }

      .image-container {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 250px;
      }

      .image-container.image-landscape {
        margin-top: 50px;
        margin-bottom: 50px;

        .pricing-image {
          height: 200px;
        }
      }

      .image-container::after {
        content: '';
        position: absolute;
        bottom: -10px;
        width: 70%;
        height: 20px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        filter: blur(10px);
        z-index: -1;
      }
    }

    .card-price {
      background-color: #f77f00;
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
      padding: 10px;
      text-align: center;
      border-radius: 5px;
      margin-top: 10px;
    }

    .card-footer {
      padding: 15px;
      text-align: center;
      border-top: 0;
      background-color: inherit;

      .btn-primary {
        background-color: #FFFFFF;
        border-color: #f77f00;
        color: #f77f00;
        font-weight: 600;
        padding: 10px 20px;
        font-size: 1rem;
        text-transform: uppercase;
        position: relative;
        overflow: hidden;
        transition: color 0.4s;

        &.btn-animated::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 300%;
          height: 300%;
          background-color: #f77f00;
          transition: all 0.4s;
          border-radius: 50%;
          z-index: 0;
          transform: translate(-50%, -50%) scale(0);
        }

        &.btn-animated:hover::before {
          transform: translate(-50%, -50%) scale(1);
        }

        &.btn-animated:hover {
          color: white;
        }

        &.btn-animated span {
          position: relative;
          z-index: 1;
          color: inherit; /* Ensure the text color remains consistent */
        }
      }
    }
  }
}
