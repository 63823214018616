.whyus-container {
  background-color: #F68D26;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .fade-in-right {
    animation: fadeInRight 1s ease-in-out;
  }

  .hidden {
    opacity: 0;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .whyus-title {
    padding: 100px 20px;
    font-size: 2rem;
    color: #000000;
    text-align: center;
    width: 100%;
    margin: 0;

    font-family: 800;
    font-size: 5rem;
  }


  .whyus-content {
    padding: 100px 20px;
    background-color: #FFFFFF;
  }

  .item {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 30px;

    .circle {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #F68D26;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      font-size: 1.5rem;
      margin-right: 20px;
      position: relative;
      flex-shrink: 0;
      /* Prevent the circle from shrinking */
    }

    .text {
      h2 {
        font-size: 2.5rem;
        color: #F68D26;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
        font-size: 1.5rem;
        color: #333;
      }
    }
  }
}