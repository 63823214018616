@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;500;600;700&display=swap');

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Roboto', sans-serif !important;
}

.content-container {
    width: 100%;
    padding: 30px 30px;
}

.overlay {
    color: #fff;
    position: absolute;
    z-index: 12;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
}

.custom-button {
    text-decoration: none;
    color: #1c2135;
    border-radius: 2px;
    cursor: pointer;
    background-color: #ffc000;
    border: 2px solid #ffc000;
    margin-left: 10px;
    margin-right: 10px;
}

.verticalLine {
    display: inline-flex;
    width: 1px;
    height: 100%;
    border: 1px solid silver;
    border-radius: 2px;
    background-color: silver;
    float: left;
}

.terms-of-use-font {
    color: #2385C6;
    padding-left: 2%;
}

.terms-of-use-container {
    padding-left: 10%;
    padding-right: 10%;
}

.uncontrolledAccordion-font{
    text-align: justify;
}

.vl {
    border-left: 6px solid #3f51b5;
}

.hl{
    border-top: 4px solid #3f51b5;
    border-radius: 20px;
}

.banner-top {
    background-color: #fff !important;
    width: 100% !important;
    position: fixed !important;
    z-index: 15;
    text-align: center;
    margin: 0px auto -115px;
    top: 0;
    padding: 0 !important;
    opacity: 0.9;
}

.cs-topper {
    font-size: var(--topperFontSize);
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: inherit;
    letter-spacing: .1em;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 0.25rem;
    display: block;
}

.cs-title {
    font-size: var(--headerFontSize);
    font-weight: 900;
    line-height: 1.2em;
    text-align: inherit;
    max-width: 43.75rem;
    margin: 0 0 1rem 0;
    color: var(--headerColor);
    position: relative;
}

.cs-text {
    font-size: var(--bodyFontSize);
    line-height: 1.5em;
    text-align: inherit;
    width: 100%;
    max-width: 40.625rem;
    margin: 0;
    color: var(--bodyTextColor);
}
                            
body {
    background-color: black;
}

.page-content {
    padding-top: 76px;
    position: relative; // Ensure the parent container is positioned
    z-index: 1; // Default stacking order for the container
    
}