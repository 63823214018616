.about-us-page {
  z-index: -1;

  &::before {
    content: "";
    position: absolute; // Position the pseudo-element
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/wave.svg') no-repeat center center;
    background-size: cover; // Adjust as necessary
    z-index: -1; // Place it behind the container
    opacity: 0.5; // Adjust this value to change the transparency
    transform: scaleX(-1); // Flip the background image horizontally
  }
    .main-content {
      position: relative;
      width: 100%;
      padding: 70px 0;
  
  
      .container {
        position: relative;
        z-index: 2; /* Ensure content is above the overlay */
      }
  
      .about-section {
        margin-bottom: 2rem;
  
        h2 {
          font-size: 2rem;
          color: #000; /* Change color to white for better contrast */
          margin-bottom: 1.5rem;
          position: relative;
          display: inline-block;
          text-align: center;
          width: 100%;
        }
  
        h2::after {
          content: '';
          display: block;
          width: 50%;
          height: 2px;
          background: #f68d26;
          margin: 1rem auto 0;
        }
  
        p {
          font-size: 1.1rem;
          color: #000; /* Change color to white for better contrast */
          line-height: 1.6;
        }
      }
      @media (min-width: 768px) {
        .about-section {
          h2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
          }
      
          p {
            font-size: 1.25rem;
            line-height: 1.8;
            margin-bottom: 1.5rem;
          }
        }
      }
      
      @media (min-width: 992px) {
        .about-section {
          h2 {
            font-size: 3rem;
            margin-bottom: 2.5rem;
          }
      
          p {
            font-size: 1.5rem;
            line-height: 2;
            margin-bottom: 2rem;
          }
        }
      }
  
      .about-image {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  
    hr {
      border: 0;
      height: 1px;
      background: #f68d26;
      margin: 5rem 0;
    }
  
    .contact-us-section {
      padding: 70px 0;
      background-color: inherit;
  
      .text-center {
        h1 {
          font-size: 2.5rem;
          margin-bottom: 1.5rem;
        }
  
        h3 {
          font-size: 1.5rem;
          margin-bottom: 2rem;
          color: #555;
        }
  
        .btn-primary {
          background-color: #f68d26;
          border-color: #f68d26;
          padding: 0.75rem 1.5rem;
          font-size: 1.2rem;
          font-weight: bold;
          transition: background-color 0.3s, border-color 0.3s;
  
          &:hover {
            background-color: #e55b00;
            border-color: #e55b00;
          }
        }
      }
    }
  }
  