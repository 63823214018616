.hero {
    position: relative;

    .carousel {
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            opacity: 0;
            margin: 0 50%;
        }

        .desktop-banner-image {
            height: 700px;
            width:100%;
            background: no-repeat center center;
            background-size: cover;
            object-fit: cover;
            object-position: left;
            display: block;

            @media (max-width: 768px) {
                display: none;
            }
        }

        
        .mobile-banner-image {
            display:none;
            width:100%;

            
            @media (max-width: 768px) {
                display: block;
                background: no-repeat center center;
                object-fit: contain;
            }
        }

        .carousel-indicators {
            position: absolute;
            right: 0;
            bottom: 10px;
            left: 0;
            z-index: 15;
            display: flex;
            justify-content: center;
            padding-left: 0;
            margin: 0 15%;
            list-style: none;
        }
    }

    .hero-desc {
        position: absolute;
        top: 200px;
        left: 5%;
        right: 5%;
        z-index: 1;
    }

    .cs-button-6 {
        text-align: center;
        font-size: calc(16 / 16 * 1rem);
        line-height: clamp(2.875rem, 8vw, 3.5rem);
        text-decoration: none;
        font-weight: 700;
        min-width: calc(174 / 16 * 1rem);
        margin: auto;
        color: #FE8000;
        padding: 0 calc(24 / 16 * 1rem);
        box-sizing: border-box;
        background-color: #fff;
        border: 2px solid #FE8000;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        gap: calc(24 / 16 * 1rem);
        position: relative;
        z-index: 1;
        transition: color 0.3s;
        text-shadow: none;

        &:before {
            content: "";
            width: calc(6 / 16 * 1rem);
            height: 100%;
            background: #FE8000;
            opacity: 1;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: width 0.3s;
            z-index: -1;
        }

        .cs-icon {
            width: calc(24 / 16 * 1rem);
            height: auto;

            path {
                transition: fill 0.3s;
            }
        }

        &:hover {
            color: #fff;
            &:before {
                width: 100%;
            }

            .cs-icon path {
                fill: #fff;
            }
        }
    }

    .carousel-caption {
        color: #ffffff; // Set text color to white
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); // Increase the shadow for better contrast
        text-align: start;
        font-weight: bolder;
        text-align: left;

        @media (max-width: 768px) {
            display: block;
        }

        p.n_bannerTitle {
            font-size: 1.5rem; // Smaller font size for smaller screens
            font-weight: bold;
            color: #ffffff; // Brighter color for title text
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); // Ensure shadow is applied

            @media (min-width: 768px) {
                font-size: 1.75rem; // Adjust the size as needed
            }
        }

        p.n_bannerText {
            font-size: 1rem; // Smaller font size for smaller screens
            font-weight: bold;
            color: #ffffff; // White color for normal text
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); // Ensure shadow is applied

            @media (min-width: 768px) {
                font-size: 1.2rem; // Adjust the size as needed
            }
        }
    }

    .carousel-caption-mobile {
        display: block;
        text-align: center;
        padding: 10px;
        background-color: #F3F2F0;

        .banner-title {
            color: #000;

            .n_bannerTitle {
                font-size: 24px;
            }

            .n_bannerText {
                font-size: 18px;
            }

        }
    }

    .carousel-item {
        position: relative;

        &::before {
            content: ''; // Create a pseudo-element to serve as an overlay
            position: absolute; // Position the overlay absolutely
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2); // Semi-transparent black overlay
        }

        
        @media (max-width: 768px) {
            &::before {
                background: rgba(0, 0, 0, 0.0); // Semi-transparent black overlay
            }
        }

    }
}
