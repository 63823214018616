.top-categories-style-2 {
    padding-top: 70px;
    padding-left: 100px;
    padding-right: 100px;

    .card-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 20px;
    }

    .card {
        background-color: #fff;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 300px;
        height: 300px;
        margin: 20px;
        position: relative;
        transition: transform 0.3s ease;


        &:hover {
            transform: scale(1.05);
        }
    }

    .card-image {
        height: 300px;
        background-size: cover;
        background-position: center;
    }

    .card-image-1 {
        background-image: url('../../../assets/frontpage/categories/Shoplot\ 3D\ Box\ Up\ Signboard.jpg');
    }

    .card-image-2 {
        background-image: url('../../../assets/frontpage/categories/3D LED Backlit Metal Gold Signage for Office.jpeg');
    }

    .card-image-3 {
        background-image: url('../../../assets/frontpage/categories/High Heel Logo customized double sided lightbox.jpg');
    }

    .card-content {
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(255, 145, 77);
        color: #fff;
        padding: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        clip-path: polygon(0 0, 20% 0, 80% 100%, 0% 100%);
    }


    .card-content-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        margin-right: 60%;
        margin-top: 40%
    }

    .card-title {
        margin: 0;
        font-size: 24px;
    }

    .card-link {
        margin-top: 10px;
        color: #fff;
        text-decoration: none;
        display: flex;
        align-items: bottom;

        &::after {
            content: '>';
            margin-left: 5px;
        }
    }


    @media (max-width: 768px) {
        
    padding-left: 0px;
    padding-right: 0px;

        .card-container {
            flex-direction: column;
            align-items: center;
        }

        .card {
            width: 90%;
        }

    }

}