
.footer {
  
    .footer-text {
      color: #fff;
      margin-bottom: 1px;
    }

    .footer-title-text {
        color: #fff;
        font-weight: bold;
      }

    .footer-link {
      text-decoration: none;
    }

    .footer-padding-bottom {
        padding-bottom: 2px;
    }

    .white-line {
      width: 100%;
      height: 1px;
      color: #fff;
    }

    .social-section {
        .icons {
            width: 50px;
            text-align: center;
            text-decoration: none;
            margin: 5px 12px;

            &:hover {
                opacity: 0.7;
            }

            .icon {
                border-radius: 50%;
                height: auto;
                width: 100%;
                font-size: 30px;
                padding: 10px;
            }

            .facebook-icon {
                background-color: #4267B2;
                color: #fff;
            }

            .instagram-icon {
                background-color: #f77737;
                color: #fff;
            }
        }
    }
    .all-right-reserved {
        color: #fff;

        .privary-between {
            padding: 10px;
            color: #fff;
        }
    }
}

.bi-geo-alt-fill::before {
    padding: 10px;
    font-size: 20px;
}

.bi-telephone-fill::before {
    padding: 10px;
    font-size: 20px;
}


.bi-envelope-fill::before {
    padding: 10px;
    font-size: 20px;
}

.bi-clock-fill::before {
    padding: 10px;
    font-size: 20px;
}