.product-subcatalog-page {
    padding-top: 50px;

    
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 20px;
    }
  
    .title {
      text-align: center;
      padding-top: 50px;
      margin-bottom: 30px;
      font-size: 3.5rem;
      font-weight: 600;
      color: #333;

      &::after {
        content: "";
        display: block;
        width: 50%;
        height: 2px;
        background: #f68d26;
        margin: 1rem auto 0;
      }
    }
    
    .subcatalog-description {
      font-size: 1.3rem;
      padding: 0 150px;
    }
  
    .subcatalog-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-top: 40px;
  
      .subcatalog-item {
        flex: 0 1 calc(33.333% - 20px);
        margin: 0.5rem;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 15px;
        border-radius: 8px;
        text-align: center;
  
        img {
          max-width: 100%;
          height: 400px;
          width: 400px;
          border-bottom: 1px solid #ddd;
          margin-bottom: 10px;
        }
  
        h2 {
          font-size: 1.5em;
          color: #555;
        }
  
        p {
          font-size: 1em;
          color: #777;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    @media (max-width: 768px) {
      .subcatalog-description {
        font-size: 1.3rem;
        padding: 20px;
      }

      .subcatalog-list {
        .subcatalog-item {
          flex: 0 1 calc(50% - 1rem); // 2 items per row on smaller screens
        }
      }
    }
  
    @media (max-width: 480px) {
      .subcatalog-list {
        .subcatalog-item {
          flex: 0 1 calc(100% - 1rem); // 1 item per row on mobile screens
        }
      }
    }
  }
  