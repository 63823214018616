.panel-background {
  background-image: url('../../../assets/frontpage/introduction/introduction-bg.png'); // Use the path to your background image
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 700px;
  /* Ensure it covers the viewport height */
}

.content-container {
  position: relative;
  z-index: 1;
}

.introduction-text {
  font-size: 1.5rem;
  color: white;

  h2 {
    font-size: 3rem;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.fade-in-left {
    animation: fadeInLeft 1s ease-in-out;
  }

  &.hidden {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .panel-background {
    height: 900px;

  }

  .introduction-text {
    padding-right: 6rem;
    font-size: 1.2rem;

    h2 {
      font-size: 2.5rem;
    }
  }
}