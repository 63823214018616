.product-detail-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;

  &::before {
    content: "";
    position: absolute; // Position the pseudo-element
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/wave.svg') no-repeat center center;
    background-size: cover; // Adjust as necessary
    z-index: -1; // Place it behind the container
    transform: scaleX(-1); // Flip the background image horizontally
  }

  @media (max-width: 768px) {
    .product-detail-card {
      flex-direction: column;

      .image-section {
        order: 1;
        width: 100%;


        .main-image-container {
          height: auto;
        }
      }

      .info-section {
        order: 2;
        width: 100%;
        text-align: center;
        padding: 10px;

        .product-title
        {
          font-size: 2rem;
        }
      }

    }
  }
}

.breadcrumb {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: left;
  /* Center align the breadcrumb */
  margin-bottom: 2rem;

  .breadcrumb-path {
    font-weight: 600;
    font-size: 18px;
  }

}

.product-detail-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  background: inherit;
  padding: 2rem;
  align-items: flex-start;

  .image-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-image-container {
      width: 100%;
      height: 500px;
      /* Increased height */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      .main-product-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .thumbnail-images {
      display: flex;
      gap: 0.5rem;

      .thumbnail-image {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        cursor: pointer;
        transition: transform 0.3s;

        &.selected {
          border: 2px solid #FE7F00;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .info-section {
    flex: 1;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;

    .product-title {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .product-description {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }

    .product-button {
      padding: 1rem 2rem;
      background: white;
      color: #FE7F00;
      border: 5px solid white;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 800;
      cursor: pointer;
      transition: background 0.3s, transform 0.3s;
      margin-top: auto;
      margin-bottom: 76px;
      width:100%;

      &:hover {
        background: darken(#FE7F00, 0%);
        transform: scale(1.05);
        color: white;
      }
    }
  }
}