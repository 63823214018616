/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #collection-1577 {
    padding-top:100px;
    padding-bottom:100px;
    
    position: relative;
    z-index: 1;
    
    // background-color: rgb(243, 242, 240);

    
  }

  #collection-1577 .cs-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 48px - 64px */
    gap: clamp(3rem, 6vw, 4rem);
  }

  #collection-1577 .cs-content {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #collection-1577 .cs-title {
    margin: 0;
    font-size: 48px;

    &::after {
      content: "";
      display: block;
      width: 50%;
      height: 2px;
      background: #f68d26;
      margin: 1rem auto 0;
    }
  }

  #collection-1577 .cs-topper {
    color: var(--secondary);
  }

  #collection-1577 .cs-card-group {
    list-style: none;
    width: 100%;
    max-width: 34.375rem;
    margin: auto;
    padding: 0;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: row;
    z-index: 1;
  }

  #collection-1577 .cs-item {
    max-width: 100%;
    grid-column: span 12;
    position: relative;
    flex: 1 1 calc(33.333% - 1em); // Adjust percentage and spacing as needed
    box-sizing: border-box;
  }

  #collection-1577 .cs-item:hover .cs-background img {
    opacity: .5;
    transform: scale(1.4);
  }

  #collection-1577 .cs-link {
    text-decoration: none;
    height: 75vw;
    max-height: 21.75rem;
    padding: 2.5rem 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #collection-1577 .cs-background {
    width: 100%;
    height: 100%;
    background-color: #000;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  #collection-1577 .cs-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .6s, opacity .3s;
  }

  #collection-1577 .cs-category {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
    text-decoration: none;
    width: fit-content;
    padding: clamp(0.75rem, 2vw, 1.25rem) clamp(1rem, 3vw, 1.5rem);
    color: var(--main-white, #ffffff);
    position: absolute;
    left:0;
    top:0;
    transition: color .3s;
  }

  #collection-1577 .cs-category:hover,
  #collection-1577 .cs-link:hover>.cs-category{
    background-color: #f68d26;
    color: #ffffff;
  }


  #collection-1577 .cs-category:before {
    content: "";
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background-color: #f68d26;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: background-color 0.3s;
  }
}

/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #collection-1577 .cs-card-group {
    max-width: 80rem;
    grid-template-columns: repeat(9, 1fr);
    background-color: inherit;
  }

  #collection-1577 .cs-item {
    height: clamp(11.75rem, 25vw, 21.75rem);
    grid-column: span 3;
    margin: 0.5em; // Add margin between items
  }
}

@media only screen and (max-width: 48rem) {
  

  #collection-1577 .cs-card-group {
    z-index: 1;
  }

  
  #collection-1577 .cs-item {

    margin: 1rem 0; // Vertical margin for better spacing on mobile
    border-bottom: 1px solid white;
    border-bottom: 1px solid white;

    &:first-child {
      margin-top: 0; // Remove top margin for the first element
    }
    
    &:last-child {
      margin-bottom: 0; // Remove bottom margin for the last element
    }
    
  }
}
