.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  position: relative;
  padding-bottom: 5rem;
  z-index: -1;
  .test {
    flex-grow: 1;
  }

  .feature-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin: 0 1rem;
    position: relative;

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      width: 40px;
      height: 40px;
      background-color: #ff6f61;
      border-radius: 5px;
      z-index: -1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
    }

    .icon-wrapper {
      margin-bottom: 1rem;

      i {
        font-size: 2rem;
        color: #ff6f61; /* Icon color */
      }
    }

    .feature-title {
      font-size: 1.8rem;
      font-weight: 700;
      color: #333;
      margin-bottom: 0.8rem;
    }

    .feature-description {
      font-size: 1rem;
      color: #666;
    }
  }
}


@media (max-width: 768px) {
  .features-container {
    flex-direction: column;
    align-items: center;

    .feature-card {
      flex: 1 1 100%;
      margin: 10px 0;
    }
  }
}