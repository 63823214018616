.contact-container {
    background-color: #f8f9fa;
    padding: 100px 0;
    background-image: url('../../assets/contact/contact-bg.png');
    background-repeat: no-repeat; /* Prevent the image from repeating */
  
    .container {
      .section-title {
        font-size: 3rem;
        color: White;
        margin-bottom: 20px;
      }
  
      .section-subtitle {
        font-size: 1.2rem;
        color: #eee;
        margin-bottom: 40px;
      }
  
      .contact-card {
        background: #fff;
        border-radius: 8px;
        padding: 30px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        transition: transform 0.3s, box-shadow 0.3s;
  
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
  
        .contact-icon {
          font-size: 3rem;
          color: #f68d26;
          margin-bottom: 20px;
        }
  
        .contact-title {
          font-size: 1.5rem;
          color: #333;
          margin-bottom: 15px;
        }
  
        .contact-info {
          font-size: 1.2rem;
          color: #007bff;
          margin-top: 10px;
        }
  
        a {
          color: #007bff;
          text-decoration: none;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  