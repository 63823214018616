.services-container {
    background-color: rgb(243, 242, 240);

    .services-container-title {
        text-align: center;
        font-size: 48px;
        padding-top: 100px;
    }

    @keyframes fadeInLeft {
        from {
            opacity: 0;
            transform: translateX(-100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    @keyframes fadeInRight {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    .fade-in-left {
        animation: fadeInLeft 1s ease-in-out;
    }
    
    .fade-in-right {
        animation: fadeInRight 1s ease-in-out;
    }
    
    .hidden {
        opacity: 0;
    }
}

.services-section {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Horizontally center the blocks */
    padding: 100px 40px; /* Adjusted padding for better spacing */
    width: 100%;
    box-sizing: border-box;
}

.services-block {
    position: relative; /* Enable positioning for child elements */
    flex: 1; /* Each block takes up equal space */
    max-width: 650px; /* Adjusted maximum width for better readability */
    margin: 40px 60px; /* Increased vertical margin for better spacing */
    text-align: center;
    padding: 20px; /* Increased padding for better spacing */
    padding-top: 50px;
    background-color: #fff; /* White background for each service block */
    border-radius: 8px; /* Rounded corners for a softer look */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Slightly increased shadow for depth */
    transition: transform 0.3s ease; /* Smooth transition on hover */
    min-width: 200px;
}

.services-block:hover {
    transform: translateY(-5px); /* Lift the block slightly on hover for interactive feel */
}

.icon {
    position: absolute; /* Position the icon absolutely */
    top: -40px; /* Adjust the position so half is above the card */
    left: 50%; /* Center the icon horizontally */
    transform: translateX(-50%); /* Adjust for perfect centering */
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    background-color: #DA8935; /* Example color, change as needed */
    border-radius: 50%; /* Make the icon a circle */
    border: 5px solid #fff; /* Add border to match the card's background */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add a shadow for depth */
    text-align: center;

    img {
        margin-top: 12px;
        width: 45px;
        height: 45px;
    }
}

.services-title {
    font-size: 48px;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
}

.services-text {
    font-size: 24px;
    color: #666;
    line-height: 1.5;
    padding: 30px
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .services-section {
        padding: 50px 20px; /* Reduce padding for smaller screens */
    }

    .services-block {
        margin: 20px 10px; /* Adjust margin for smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .services-container-title {
        font-size: 36px; /* Reduce title size for smaller screens */
        padding-top: 50px;
    }

    .services-title {
        font-size: 36px; /* Reduce service title size for smaller screens */
    }

    .services-text {
        font-size: 16px; /* Reduce text size for smaller screens */
    }

    .icon {
        width: 60px; /* Adjust icon size for smaller screens */
        height: 60px; /* Adjust icon size for smaller screens */
        top: -30px; /* Adjust top position for smaller screens */
    }

    .icon img {
        width: 35px; /* Adjust icon image size for smaller screens */
        height: 35px; /* Adjust icon image size for smaller screens */
    }
}

@media (max-width: 480px) {
    .services-section {
        padding: 30px 10px; /* Further reduce padding for very small screens */
    }

    .services-block {
        margin: 15px 5px; /* Further adjust margin for very small screens */
        padding: 10px; /* Further adjust padding for very small screens */
    }

    .services-container-title {
        font-size: 28px; /* Further reduce title size for very small screens */
    }

    .services-title {
        font-size: 28px; /* Further reduce service title size for very small screens */
        padding-top: 20px;

    }

    .services-text {
        font-size: 14px; /* Further reduce text size for very small screens */
        margin-bottom: 0;
    }

    .icon {
        width: 50px; /* Further adjust icon size for very small screens */
        height: 50px; /* Further adjust icon size for very small screens */
        top: -25px; /* Further adjust top position for very small screens */
    }

    .icon img {
        width: 25px; /* Further adjust icon image size for very small screens */
        height: 25px; /* Further adjust icon image size for very small screens */
    }
}

