/*-- -------------------------- -->
<---         Services           -->
<--- -------------------------- -*/


@media only screen and (min-width: 0rem) {
    #services-1829 {
        padding-top:120px;
        padding-bottom:120px;
        overflow: hidden;
        position: relative;

        .cs-container {
            width: 100%;
            max-width: 880px;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: clamp(3rem, 6vw, 4rem);
            position: relative;
        }

        .cs-content {
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .cs-title {
            font-size: 4rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        .cs-text {
            font-size: 1.2rem;
            color: #333;
        }

        .cs-card-group {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 1rem;
            padding-left: 0;
        }

        .cs-item {
            border-radius: 25px;
            list-style: none;
            background: white;
            overflow: hidden;
            position: relative;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s;

            &:hover {
                transform: translateY(-5px);
            }

            .cs-link {
                text-decoration: none;
                color: inherit;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            .cs-picture {
                width: 100%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    transition: transform 0.3s;
                }
            }

            .cs-flex {
                padding: 1rem;
                background-color: white;
                text-align: center;
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .cs-h3 {
                font-size: 1.2rem;
                font-weight: 600;
                margin: 0;
            }
        }
    }
}

@media only screen and (min-width: 48rem) {
    #services-1829 {
        .cs-card-group {
            gap: 1.5rem;
        }
        .cs-picture img {
            height: 400px;
        }

        .cs-item {
            .cs-flex {
                padding: 1.5rem;
            }

            .cs-h3 {
                font-size: 1.4rem;
            }
        }
    }
}

@media only screen and (min-width: 64rem) {
    #services-1829 {
        .cs-container {
            max-width: 1280px;
        }
        .cs-picture img {
            height: 400px;
        }
        .cs-item {
            .cs-h3 {
                font-size: 1.6rem;
            }
        }
    }
}
