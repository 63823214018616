.contact-us-section {
    padding: 80px 0;
    background-color: #f8f9fa;
  
    .section-title {
      font-size: 2.5rem;
      color: #333;
      margin-bottom: 20px;
    }
  
    .section-subtitle {
      font-size: 1.2rem;
      color: #666;
      margin-bottom: 40px;
    }
  
    .contact-info {
      .contact-item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        padding:px;
  
        .contact-icon {
          font-size: 2rem;
          color: #f68d26;
          margin-right: 10px;
        }
  
        a {
          color: #333;
          text-decoration: none;
          transition: color 0.3s;
  
          &:hover {
            color: #f68d26;
          }
        }
      }
    }
  }
  