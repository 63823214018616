.landing-wrapper {
    align-items: center;
    margin: 30px 0;

    .separator {
        padding: 40px;

        .learn-more-button {
            display: inline-block;
            color: black;
            margin: 30px 0;
        }
    }

    .custom-button-wrapper {
        text-decoration: none !important;

        .services-custom-button {
            border: 1px solid #000;
            background-color: #fff;
            color: black;
            text-align: center;
            padding: 10px 0;
            margin: 0 30px;
            border-radius: 5px;

            &:hover {
                color: black;
                border: 2px solid #2385c6;
            }

            img {
                height: 200px;
            }
        }
    }
    
    .contact-section {
        h4 {
            color: #4267B2;
        }

        .contact-alignment {
            align-items: center;
            margin: 20px 0;
        }

        .icons {
            font-size: 50px;
        }

        .blue-icon {
            color: #4267B2;
        }

        .green-icon {
            color: #25D366;
        }
    }    
}



.services-content {
    position: relative;
    padding: 0 100px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.border-radius{
    border-radius: 15px;
}

.small-container-purple {
    border-radius: 5px;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: white;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
}

.small-container-yellow {
     border-radius: 5px;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: white;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
}

.about-us {
    text-align: justify;
    width:"100%";
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 10px;
    padding-bottom: 40px;
}





.about-background {
    background-size: cover;
    width: 100%;
}

.contact-background {
    background-size: cover;
    width: 100%;
}

.core-value-background {
    background-size: cover;
    width: 100%;
}

.project-container-background{
    background-size: cover;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.fa-handshake-o:before {
    font-size: 80px;
    color: #3f51b5
}

.fa-truck:before {
    font-size: 80px;
    color: #3f51b5
}

.fa-gears:before, .fa-cogs:before {
    font-size: 80px;
    color: #3f51b5
}

.fa-clock-o:before {
    font-size: 80px;
    color: #3f51b5
}

.guarantee-container {
    text-align: center;
    padding: 30px;
}

.guarantee-icon-container {
    margin-bottom: 20px;
}

.number-count-container {
    font-size: 50px;
    color: #3f51b5;
    text-align: end;
    padding-right: 50px;
}

.number-count-desc {
    font-size: 15px;
    //color: #ab982d;
    color: white;
    font-weight: 600;
}

.project-container {
    display: flex;
    justify-content: center;
}

.bi-buildings::before {
    padding-right: 20px;
}

.bi-people-fill::before {
    padding-right: 20px;
}