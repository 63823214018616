.custom-navbar {
    width: 100% !important;
    position: fixed !important;
    z-index: 12;
    text-align: center;
    padding: 0 !important;
    opacity: 1;

    .nav-container {
        padding: 0 0px;
    }
    .navbar-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .navbar-brand {
        color: #1c2135;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .nav-buttons {
        color: #fafafa;
    }

    .nav-link{
        color:white !important;
    }
}

.navbar-toggler
{
    background-color: #a2a2a2;
}

#navbarNav {
    justify-content: end;
}