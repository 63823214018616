// Custom.scss
// Color

$primary: #F68D26;
$secondary: #7C847C;

//$secondary: #7C7C7C;
$dark: #040B04;


$danger: #ff4136;

// Include any default variable overrides here (though functions won't be available)

@import "../../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here